<template>
    <div class="add-delegate">
        <div style="padding: 20px 40px">
            <el-form ref="form" :model="delegateForm" :rules="delegateRules">
                <div style="margin-bottom: 40px">
                    <div class="title-form">任务名称</div>
                    <el-form-item prop="title">
                        <el-input
                            type="text"
                            v-model="delegateForm.title"
                            :show-word-limit="true"
                            maxlength="30"
                            minlength="4"
                            placeholder="请填写需要委托的服务名称">
                        </el-input>
                    </el-form-item>
                </div>

                <div style="margin-bottom: 40px">
                    <div class="title-form">任务详情</div>
                    <el-form-item prop="detail">
                        <el-input
                            type="textarea"
                            v-model="delegateForm.detail"
                            :show-word-limit="true"
                            :rows="10"
                            maxlength="500"
                            placeholder="请填写需要委托的服务具体要求">
                        </el-input>
                    </el-form-item>
                </div>

                <div style="margin-bottom: 40px">
                    <div class="title-form">任务成果要求</div>
                    <el-form-item prop="ask">
                        <el-input
                            type="textarea"
                            v-model="delegateForm.ask"
                            :show-word-limit="true"
                            :rows="8"
                            maxlength="500"
                            placeholder="请填写想要达到的服务成果要求">
                        </el-input>
                    </el-form-item>
                </div>

                <div style="margin-bottom: 40px">
                    <div class="title-form">任务提交格式</div>
                    <el-form-item prop="format">
                        <el-input
                            type="textarea"
                            v-model="delegateForm.format"
                            :show-word-limit="true"
                            :rows="4"
                            maxlength="200"
                            placeholder="请填写提交格式要求">
                        </el-input>
                    </el-form-item>
                </div>

                <div style="display: flex; margin-bottom: 40px">
                    <div style="flex: 1">
                        <div class="title-form">完成日期</div>
                        <el-form-item prop="finishTime">
                            <div class="block">
                                <el-date-picker
                                    size="small"
                                    v-model="delegateForm.finishTime"
                                    type="datetime"
                                    :picker-options="pickerOptions"
                                    value-format="yyyy-MM-dd HH:mm:ss">
                                </el-date-picker>
                            </div>
                        </el-form-item>
                    </div>
                </div>

                <div style="display: flex; margin-bottom: 40px" v-if="roomId !== 0">
                    <div style="flex: 1">
                        <div class="title-form">任务承接方</div>
                        <el-form-item prop="trustee">
                            <el-select v-model="delegateForm.trustee" clearable placeholder="请从room成员中选择" style="width: 70%">
                                <el-option v-for="(item, index) in shopList" :key="index" :label="item.nickName" :value="item.uuid">{{item.nickName}}</el-option>
                            </el-select>
                        </el-form-item>
                    </div>

                    <div style="flex: 1" v-if="roomId !== 0">
                        <div class="title-form">选择协助专家</div>
                        <el-form-item prop="expert">
                            <el-select v-model="delegateForm.expertList" multiple clearable placeholder="请从room成员中选择专家" style="width: 70%">
                                <el-option v-for="(item, index) in expertList" :key="index" :label="item.nickName" :value="item.uuid">{{item.nickName}}</el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                </div>
            </el-form>

            <div style="display: flex; justify-content: center" v-if="roomId !== 0">
                <div class="foot-btn hand op" style="line-height: 20px; background-color: #2970FF; color: #FFFFFF;" @click="addOrderList">发布任务</div>
            </div>
          <div style="display: flex; justify-content: center" v-else>
            <div class="foot-btn hand op" style="line-height: 20px; background-color: #2970FF; color: #FFFFFF;" @click="editOrderList">编辑任务</div>
          </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AddDelegate",
    data() {
        return {
            delegateForm: {
                title: '',
                detail: '',
                trustee: '',
                expertList: [],
                finishTime: '',
                ask: '',
                format: ''
            },
            delegateRules: {
                title: [
                    {required: true, message: '请填写需要委托的服务名称', trigger: 'blur'}
                ],
                detail: [
                    {required: true, message: '请填写需要委托的服务具体要求', trigger: 'blur'}
                ],
                ask: [
                    {required: true, message: '请填写想要达到的服务成果要求', trigger: 'blur'}
                ],
                format: [
                    {required: true, message: '请填写提交格式要求', trigger: 'blur'}
                ],
                finishTime: [
                    {required: true, message: '请选择完成日期', trigger: 'change'}
                ],
                trustee: [
                    {required: true, message: '请选择承接方', trigger: 'change'}
                ]
            },

            shopList: [],
            expertList: [],

            pickerOptions: {
              disabledDate(time) {
                return time.getTime() <= Date.now();
              }
            },
        }
    },

    props: {
        roomId: {
            type: Number,
            default: 0
        },
        taskId: {
            type: Number,
            default: 0
        },
        taskInfo: {
            type: Object,
            default() {
                return {}
            }
        }
    },

    mounted() {
      if (this.roomId !== 0) {
        this.getRoomMember();
        this.getExpertMember();
      } else {
        this.delegateForm = {
              title: this.taskInfo.title,
              detail: this.taskInfo.detail,
              finishTime: this.taskInfo.finishTime,
              ask: this.taskInfo.ask,
              format: this.taskInfo.format
        }
      }
    },

    methods: {
        // 获取room成员
        getRoomMember() {
            this.newApi.getRoomUserList({roomId: this.roomId}).then(res => {
                this.shopList = res.data.adminList.concat(res.data.userList)
            })
        },

        // 获取room专家成员
        getExpertMember() {
            this.newApi.getRoomExpretList({roomId: this.roomId}).then(res => {
                this.expertList = res.data
            })
        },

        // 编辑订单
        editOrderList() {
          let that = this;
          that.$refs['form'].validate(function (valid) {
            if (valid) {
              let params = {};
              params.taskId = that.taskInfo.id;
              params.title = that.delegateForm.title;
              params.detail = that.delegateForm.detail;
              params.ask = that.delegateForm.ask;
              params.format = that.delegateForm.format;
              params.finishTime = that.delegateForm.finishTime;
              that.newApi.editTask(params).then(res => {
                if (res.isSuccess === 1) {
                  that.utils.sus(res.data);
                  that.$emit('susTask')
                }
              })
            }
          })
        },

        // 创建订单
        addOrderList() {
            let that = this;
            that.$refs['form'].validate(function (valid) {
                if (valid) {
                    let params = {};
                    params.sequenceId = that.taskId;
                    params.title = that.delegateForm.title;
                    params.detail = that.delegateForm.detail;
                    params.trustee = that.delegateForm.trustee;
                    params.ask = that.delegateForm.ask;
                    params.format = that.delegateForm.format;
                    params.expertList = [];
                    for (const item of that.delegateForm.expertList) {
                        let object = {}
                        object.uuid = item;
                        object.money = 0;
                        params.expertList.push(object)
                    }
                    params.expertList = JSON.stringify(params.expertList);
                    params.finishTime = that.delegateForm.finishTime;
                    that.newApi.addTask(params).then(res => {
                        if (res.isSuccess === 1) {
                            that.utils.sus(res.data);
                            that.$emit('susTask')
                        }
                    })
                }
            })
        },
    }
}
</script>

<style scoped>
.title-form {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 12px;
}

.foot-btn {
  width: 128px;
  height: 20px;
  font-size: 16px;
  border-radius: 5px;
  text-align: center;
  padding: 10px;
}
</style>
