<template>
    <div class="service-card">
        <div class="shop-box">
            <img :src="data.images[0].url" style="width: 100%; height: 150px; border: 1px #999999 solid; margin-bottom: 10px">
            <div style="display: flex; margin-bottom: 12px; min-height: 32px">
                <img :src="item.url" style="width: 44px; height: 32px; border: 1px #999999 solid; margin-right: 6px" v-for="(item, index) in imageList" :key="index">
            </div>
            <div style="display: flex; justify-content: space-between; margin-bottom: 10px">
                <div style="color: #FF0000; font-size: 20px; font-weight: 600; text-overflow: ellipsis; white-space: nowrap; overflow: hidden">￥{{data.minPrice}}</div>
            </div>
            <div style="margin-bottom: 16px;text-overflow:ellipsis;-webkit-line-clamp:2;-webkit-box-orient:vertical;overflow:hidden;display:-webkit-box">{{data.title}}</div>
            <div style="display: flex; justify-content: space-between; margin-bottom: 10px">
                <div style="font-size: 14px; line-height: 28px">
                    <span style="color: #999999">成交量：</span>
                    <span style="color: #FF9800">0</span>
                </div>
                <span @click="$router.push({path: '/shop/serviceDetails/' + data.id, query: {roomId: roomId, taskId: taskId}})" class="expert-btn hand op" style="text-align: center">查看详情</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "serviceCard",
    data() {
        return {
            imageList: []
        }
    },

    props: {
        data: {
            type: Object,
            default() {
                return {}
            }
        },

        roomId: {
            type: Number,
            default() {
                return 0
            }
        },

        taskId: {
            type: Number,
            default() {
                return 0
            }
        },
    },

    mounted() {
        this.imageList = this.data.images.splice(1, 5)
    },

    methods: {

    },
}
</script>

<style scoped>
.service-card .shop-box {
    margin-bottom: 16px;
    padding: 16px 12px;
    border: 1px #DCDCDC solid;
}

.service-card .look-btn {
    width: 82px;
    height: 22px;
    border: 1px #FF9800 solid;
    color: #FF9800;
    padding: 6px 12px;
    border-radius: 5px;
}

.service-card .expert-btn {
    width: 82px;
    height: 22px;
    background-color: #FF9800;
    color: #FFFFFF;
    padding: 6px 12px;
    border-radius: 5px;
}

.service-card .old-ico {
    width: 46px;
    height: 10px;
    background-color: #FF9800;
    color: #FFFFFF;
    margin-right: 8px;
    padding: 0 2px;
    border-radius: 5px;
}

.service-card .up-ico {
    width: 14px;
    height: 14px;
    background-color: #0B9D35;
    color: #FFFFFF;
    margin-right: 8px;
    padding: 0 2px;
    border-radius: 5px;
}
</style>