<template>
    <div class="product-form">
        <div style="padding: 20px 40px" ref="message">
            <div class="title-form">选择服务商家</div>
            <el-select v-model="shopId" clearable @change="checkoutShop" placeholder="请从room成员中选择服务商家" style="width: 300px">
                <el-option v-for="(item, index) in shopList" :key="index" :label="item.name" :value="item.id" style="padding-top: 2px; padding-bottom: 2px">
                    <img :src="item.logo.img_url" style="width: 28px; height: 28px; border-radius: 50%; float: left">
                    <div style="font-size: 16px; margin-left: 20px; float: left; line-height: 28px">{{item.name}}</div>
                </el-option>
            </el-select>
            <div style="margin-top: 20px; display: flex; flex-wrap: wrap">
                <div class="shop-box" v-for="(item, index) in goodsList" :key="index">
                    <CaseCard :questId="taskId" :roomId="roomId" :data="item" :beFrom="'2'"></CaseCard>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CaseCard from "../CaseCard";
export default {
    name: "ProductForm",
    components: {CaseCard},
    data() {
        return {
            serviceForm: {},
            serviceRules: {},

            shopList: [],
            shopId: '',
            goodsList: [],

            page: 1,
            pageSize: 10,
            more: 1
        }
    },
    props: {
        roomId: {
            type: Number,
            default: 0
        },

        taskId: {
            type: Number,
            default: 0
        },
    },

    mounted() {
        let that = this;
        this.getRoomShopper();
        this.$refs.message.onscroll = function(){
            if(that.$refs.message.scrollHeight - that.$refs.message.scrollTop - that.$refs.message.clientHeight < 10){
                if (that.more == 1) {
                    that.getGoodsList(that.shop);
                }
            }
        }
    },

    methods: {
        // 获取Room中的商家
        getRoomShopper() {
            this.newApi.getRoomShopList({roomId: this.roomId}).then(res => {
                if (res.isSuccess === 1) {
                    this.shopList = res.data;
                    this.shopId = this.shopList[0].id;
                    this.getGoodsList(this.shopId)
                }
            })
        },

        // 切换店铺
        checkoutShop(shopId) {
            this.page = 1;
            this.more = 1;
            this.goodsList = [];
            this.getGoodsList(shopId)
        },

        // 获取服务列表
        getGoodsList(shopId) {
            this.newApi.getShopGoodsList({type:1,shopId: shopId, page: this.page, pageSize: this.pageSize}).then(res => {
                if (res.isSuccess === 1) {
                    for (let i = 0; i < res.data.length; i++) {
                        this.goodsList.push(res.data[i]);
                    }
                    if (res.data.length < 10) {
                        this.more = 0;
                    }
                    this.page++;
                }
            })
        },

        // 创建订单
        addOrderList() {

        },
    }
}
</script>

<style scoped>
.title-form {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 12px;
}

.product-form {
    width: 100%;
    overflow-y: scroll;
    min-height: 400px;
    max-height: 1000px;
    margin-right: 10px;
}

.product-form .shop-box {
    width: 31%;
    margin-right: 10px;
}
</style>