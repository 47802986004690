<template>
    <div class="case-card">
        <div class="shop-box">
            <div style="width: 100%; height: 236px; margin-bottom: 10px;">
                <el-carousel indicator-position="outside" height="206px" arrow="never" :autoplay="false">
                    <el-carousel-item v-for="(item, index) in data.images" :key="index">
                        <img style="width: 100%; height: 100%" :src="item.url">
                    </el-carousel-item>
                </el-carousel>
            </div>
            <div style="margin-bottom: 16px;text-overflow:ellipsis;-webkit-line-clamp:2;-webkit-box-orient:vertical;overflow:hidden;display:-webkit-box; font-size: 18px; font-weight: 600">{{data.title}}</div>
            <div style="display: flex; justify-content: center; margin-bottom: 10px">
                <span @click="a('/shop/productDetails/' + data.id)" class="look-btn hand op" style="margin-right: 10px"><i class="el-icon-postcard" style="margin-right: 2px"></i>查看详情</span>
                <span class="expert-btn hand op" @click="getGoodInfo"><i class="el-icon-shopping-cart-2" style="margin-right: 2px"></i>选择规格</span>
            </div>

            <el-dialog :visible.sync="showGoodInfo" append-to-body width="600px" :show-close="false" class="event-form">
                <div class="top">
                    选择产品类别
                    <img src="../../expert/imgs/cancel.png" style="width: 32px; height: 32px;position: absolute;right: 30px;top: 14px;cursor: pointer;" @click="showGoodInfo = false">
                </div>
                <div style="padding: 20px">
                    <AddCart :GoodsInfo="goodsInfo" :roomId="roomId" :questId="questId" :beFrom="beFrom"></AddCart>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import AddCart from "./AddCart";
export default {
    name: "caseCard",
    components: {AddCart},
    data() {
        return {
            showGoodInfo: false,
            goodsInfo: {},
        }
    },

    props: {
        data: {
            type: Object,
            default() {
                return {}
            }
        },
        roomId: {
            type: Number,
            default() {
                return 0
            }
        },
        questId: {
            type: Number,
            default() {
                return 0
            }
        },
        beFrom: {
            type: String,
            default() {
                return ''
            }
        },
    },

    mounted() {
    },

    methods: {
        // 获取产品信息
        getGoodInfo() {
            var that = this;
            that.newApi.getShopGoodsInfo({goodsId:that.data.id}).then((res)=>{
                that.goodsInfo = res.data;
                that.showGoodInfo = true;
            })
        },

        // 关闭弹窗
        closeBox() {
            this.showGoodInfo = false
        },
    },
}
</script>

<style scoped>
.case-card .shop-box {
    margin-bottom: 16px;
    padding: 16px 20px;
    border: 1px #DCDCDC solid;
}

.case-card .look-btn {
    border: 1px #FF9800 solid;
    color: #FF9800;
    padding: 6px 10px;
    border-radius: 5px;
}

.case-card .expert-btn {
    background-color: #FF9800;
    color: #FFFFFF;
    padding: 6px 10px;
    border-radius: 5px;
}

.case-card .event-form .top {
    text-align: center;
    position: relative;
    font-size: 20px;
    font-weight: 600;
    color: #444444;
    border: 1px solid #CECFD0;
    height: 60px;
    box-sizing: border-box;
    line-height: 60px;
}

.event-form .el-dialog__header {
    padding: 0;
}

.event-form .el-dialog__body {
    padding: 0;
}
</style>